import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g
        id="B"
        transform="matrix(0.006578, 0, 0, -0.006578, 14.853726, 82.9748)"
        fill="currentColor"
        stroke="none">
        <path
          fill="currentColor"
          d="M5035 9160 c-916 -53 -1816 -449 -2545 -1119 l-55 -50 218 2 218 2
18 53 c30 87 85 132 163 132 24 0 55 -6 69 -14 l26 -13 11 59 c14 73 49 123
106 149 52 23 80 23 132 0 56 -25 101 -83 115 -148 10 -43 16 -52 28 -47 90
37 184 -19 212 -125 l13 -51 182 0 c166 0 184 2 196 18 21 31 166 143 272 211
157 101 384 189 586 227 149 28 470 26 614 -4 217 -46 431 -134 611 -251 104
-68 175 -123 175 -135 0 -5 -10 -1 -22 10 -20 18 -21 18 -15 1 4 -11 3 -16 -3
-12 -6 3 -10 13 -10 20 0 21 -34 37 -49 22 -10 -9 -15 -8 -25 5 -7 10 -16 15
-19 11 -4 -3 -7 1 -7 11 0 13 -5 16 -20 11 -14 -4 -26 0 -38 12 -9 11 -14 14
-10 8 8 -12 -25 -45 -45 -45 -6 0 -2 9 11 18 21 16 22 20 8 31 -13 11 -14 17
-5 30 6 9 -3 1 -21 -18 -17 -18 -28 -36 -23 -40 11 -8 11 -34 -1 -41 -5 -3
-10 11 -13 30 -2 19 -10 38 -19 43 -12 7 -13 12 -3 23 8 10 8 14 0 14 -6 0
-11 -5 -11 -10 0 -6 -11 -16 -25 -22 -14 -6 -25 -17 -25 -24 0 -23 -19 -25
-39 -5 -11 11 -17 22 -15 25 2 4 -2 2 -8 -4 -17 -14 -28 -12 -28 5 0 8 -7 15
-15 15 -8 0 -15 -5 -15 -12 0 -13 -14 -2 -16 13 -1 5 -2 12 -3 15 0 4 -9 0
-18 -7 -15 -12 -16 -12 -10 3 5 14 1 17 -15 15 -14 -1 -26 6 -35 23 l-13 25 0
-27 c0 -17 5 -27 13 -25 7 1 13 -10 15 -25 3 -22 -1 -28 -15 -28 -15 0 -16 2
-3 10 13 9 13 11 0 20 -30 19 -39 12 -24 -20 9 -16 20 -30 25 -30 5 0 9 -5 9
-10 0 -6 -8 -5 -20 2 -11 7 -20 18 -20 25 0 7 -7 13 -15 13 -8 0 -15 -5 -15
-12 0 -9 -3 -9 -12 1 -11 10 -9 14 5 22 9 6 15 14 12 20 -4 5 1 12 9 15 19 7
20 14 1 38 -13 17 -15 17 -37 2 l-22 -16 27 0 c29 0 36 -14 11 -24 -8 -3 -12
-10 -9 -16 3 -5 2 -10 -3 -10 -6 0 -15 -13 -21 -30 -5 -16 -17 -30 -25 -30
-20 0 -21 16 -1 24 8 3 15 12 15 21 0 18 -17 20 -23 3 -3 -10 -7 -10 -20 1
-11 9 -17 10 -17 2 0 -6 5 -11 10 -11 6 0 10 -8 10 -17 0 -15 -2 -16 -10 -3
-5 8 -12 11 -15 8 -10 -10 4 -28 21 -28 8 0 14 -5 14 -11 0 -7 -11 -8 -30 -4
-34 7 -37 0 -14 -33 14 -20 14 -22 0 -22 -9 0 -15 3 -14 8 1 4 -3 17 -10 28
-9 18 -9 24 3 34 9 7 14 18 14 24 -1 6 3 24 9 39 15 36 15 57 1 57 -6 0 -9 -7
-5 -15 5 -15 3 -16 -48 -19 -16 -1 -31 -7 -34 -14 -5 -17 -32 -15 -38 3 -7 17
-34 21 -34 5 0 -5 6 -10 14 -10 8 0 16 -4 18 -9 2 -5 15 -13 31 -16 22 -6 26
-4 22 8 -4 8 1 17 9 20 11 5 16 0 16 -13 0 -11 5 -20 10 -20 6 0 10 -7 10 -15
0 -16 -16 -20 -24 -6 -11 17 -44 -1 -56 -30 -7 -16 -17 -26 -22 -23 -5 3 -4
12 3 20 9 11 7 15 -7 20 -11 3 -26 9 -34 13 -8 5 -26 4 -40 -2 -35 -14 -79
-17 -86 -5 -4 6 -10 5 -15 -3 -8 -12 -11 -11 -19 1 -5 8 -10 11 -10 5 0 -5 -4
-3 -10 5 -5 8 -12 11 -17 6 -4 -4 0 -12 9 -17 16 -9 16 -12 -3 -49 -20 -38
-39 -45 -39 -14 0 9 -7 14 -17 12 -10 -1 -23 1 -30 5 -7 4 -18 -2 -27 -15
l-15 -22 -9 24 c-5 13 -11 19 -14 14 -8 -13 29 -57 42 -49 5 3 10 13 10 21 0
18 19 18 26 0 3 -9 12 -12 21 -9 11 4 14 2 11 -8 -3 -8 -11 -13 -17 -12 -14 4
-15 -24 -1 -32 6 -3 10 1 10 10 0 8 7 18 16 22 17 6 34 -12 34 -37 -1 -11 -3
-10 -11 3 -10 18 -12 18 -35 -4 -20 -19 -24 -20 -24 -6 0 20 -4 21 -31 7 -22
-12 -26 -35 -4 -26 9 3 15 0 15 -8 0 -21 -12 -27 -24 -11 -9 13 -19 9 -61 -30
-48 -43 -49 -45 -31 -60 11 -8 16 -18 13 -22 -4 -3 -14 2 -21 13 -14 18 -15
18 -21 2 -7 -19 -25 -25 -25 -9 0 5 7 16 15 25 22 21 19 39 -5 31 -18 -6 -20
-3 -14 19 5 22 3 24 -16 19 -19 -5 -21 -3 -15 17 9 30 -12 23 -34 -11 -15 -22
-15 -23 1 -11 14 11 18 10 27 -5 8 -16 6 -19 -12 -18 -12 0 -28 4 -34 8 -9 6
-13 0 -13 -18 0 -21 -4 -26 -16 -21 -10 4 -25 -1 -35 -10 -18 -16 -19 -16 -19
0 0 9 -4 15 -9 12 -5 -4 -11 0 -14 6 -2 7 -8 9 -13 5 -5 -5 -1 -13 9 -18 9 -6
17 -13 17 -17 0 -9 -46 -62 -54 -62 -13 0 -5 31 9 36 8 4 15 13 15 21 0 11 -7
9 -25 -8 -13 -13 -25 -29 -25 -35 0 -6 -13 -14 -29 -17 -20 -4 -33 -16 -42
-37 -14 -31 -49 -42 -49 -15 0 18 16 20 23 3 4 -10 6 -10 6 -1 1 6 -3 14 -9
18 -5 3 -6 13 -3 23 4 13 2 13 -10 -3 -9 -11 -17 -26 -17 -34 0 -8 -6 -11 -16
-7 -14 5 -15 4 -4 -9 7 -8 11 -15 9 -16 -56 -10 -78 -1 -42 19 11 7 14 15 9
28 -7 17 -9 17 -18 -6 -7 -20 -11 -22 -18 -10 -5 8 -13 11 -17 6 -4 -5 -2 -11
5 -13 15 -6 16 -35 0 -51 -9 -9 -8 -12 6 -12 9 0 14 5 11 10 -6 9 22 15 53 11
7 -1 15 5 18 14 6 14 8 14 24 0 16 -14 18 -14 23 0 4 8 14 15 22 15 19 0 38
19 43 40 4 16 21 30 36 30 5 0 2 -8 -7 -18 -18 -20 -22 -42 -8 -42 5 0 12 11
15 25 4 14 11 23 16 20 5 -3 11 -1 15 5 10 16 25 12 19 -5 -4 -8 -12 -15 -19
-15 -8 0 -17 -9 -20 -20 -4 -14 -15 -20 -35 -20 -15 0 -30 -7 -34 -15 -3 -9
-12 -12 -22 -8 -12 5 -15 3 -9 -6 6 -10 0 -12 -24 -6 -23 5 -31 3 -31 -7 0
-10 9 -13 30 -10 24 3 30 1 30 -13 0 -14 -3 -15 -15 -5 -13 10 -15 9 -15 -8 0
-15 -5 -20 -15 -16 -9 4 -15 0 -15 -9 0 -8 -7 -17 -15 -21 -23 -9 -18 22 6 35
l21 11 -22 20 c-16 15 -25 17 -33 9 -8 -8 -8 -11 1 -11 7 0 12 -7 12 -15 0
-20 -10 -19 -26 3 -13 16 -15 16 -29 -2 -8 -11 -15 -28 -15 -37 0 -15 5 -17
39 -10 2 1 -2 7 -8 14 -15 19 -2 29 15 12 16 -16 18 -35 4 -35 -5 0 -10 -7
-10 -16 0 -12 -3 -14 -13 -6 -14 12 -42 11 -54 -2 -4 -4 -1 -11 6 -16 9 -5 11
-14 6 -26 -8 -18 -8 -18 -12 -1 -5 18 -23 24 -23 7 0 -6 -6 -10 -12 -8 -7 1
-24 -4 -38 -11 -17 -10 -25 -11 -28 -2 -5 14 -42 15 -42 0 0 -5 5 -7 12 -3 7
4 8 3 4 -4 -4 -7 -1 -12 6 -12 7 0 19 -4 27 -9 17 -11 0 -45 -29 -61 -19 -10
-20 -8 -18 31 2 29 -1 43 -11 47 -10 3 -12 0 -8 -12 8 -21 -2 -46 -18 -46 -8
0 -17 9 -20 20 -7 23 -23 18 -24 -8 -2 -69 0 -82 16 -82 7 0 13 5 13 12 0 9 3
9 11 1 13 -13 -12 -58 -26 -45 -4 4 -2 -3 4 -15 6 -13 15 -23 20 -23 17 0 6
-45 -13 -48 -11 -3 -16 0 -11 7 3 6 11 9 16 6 5 -4 9 -2 9 4 0 6 -12 14 -26
17 -23 6 -25 10 -20 39 7 34 -15 68 -29 45 -4 -6 -1 -9 6 -8 34 8 3 -50 -34
-65 -15 -6 -16 -3 -7 13 6 11 15 20 20 20 16 0 11 17 -6 24 -8 3 -12 2 -9 -4
3 -5 -4 -14 -15 -20 -16 -9 -18 -15 -10 -30 6 -11 20 -25 32 -31 18 -10 20 -9
15 5 -5 11 -1 16 13 16 10 0 21 -4 24 -9 6 -9 -28 -44 -35 -37 -2 2 -5 -9 -6
-26 -3 -34 -19 -58 -39 -58 -8 0 -14 -5 -14 -11 0 -5 4 -8 8 -5 5 3 16 -8 26
-25 19 -32 36 -38 36 -13 0 8 -4 13 -9 10 -5 -3 -11 4 -15 16 -3 13 0 26 7 29
7 4 24 18 39 32 19 18 23 28 15 36 -13 13 2 15 21 3 17 -11 5 -39 -20 -45 -12
-4 -18 -12 -16 -23 4 -24 28 -30 29 -7 0 16 1 16 9 -2 13 -30 12 -32 -11 -38
-13 -4 -18 -10 -13 -18 4 -8 3 -10 -4 -5 -14 9 -62 -22 -62 -40 0 -7 -9 -12
-20 -11 -11 1 -20 5 -20 10 0 5 9 11 20 14 11 3 20 12 20 19 0 17 -17 18 -23
2 -3 -7 -6 -2 -6 11 -1 18 -5 22 -21 18 -15 -4 -20 0 -20 14 0 10 5 19 10 19
6 0 10 4 10 9 0 4 -13 8 -30 7 -34 -1 -37 -11 -13 -38 9 -10 13 -21 9 -25 -4
-4 -11 -2 -15 5 -6 10 -11 9 -21 -4 -17 -19 -22 -44 -9 -44 5 0 9 9 9 20 0 18
3 19 22 9 16 -9 23 -9 26 0 2 6 11 11 20 11 11 0 6 -10 -18 -35 -19 -19 -30
-35 -24 -35 5 0 1 -5 -8 -11 -10 -5 -18 -16 -18 -24 0 -8 -6 -12 -14 -9 -15 6
-10 44 5 44 5 0 9 5 9 10 0 21 -20 9 -26 -15 -7 -28 -24 -34 -24 -8 0 13 -3
14 -11 6 -9 -9 -8 -16 4 -27 9 -9 20 -16 25 -16 6 0 12 -5 14 -12 2 -6 12 -9
21 -5 10 4 14 12 11 22 -8 21 59 21 76 0 9 -10 9 -15 1 -15 -6 0 -11 -7 -11
-15 0 -8 -7 -15 -15 -15 -8 0 -15 4 -15 9 0 5 7 11 15 15 8 3 15 10 15 16 0 5
-7 10 -15 10 -8 0 -15 -6 -15 -12 0 -9 -4 -8 -10 2 -8 12 -10 9 -10 -12 0 -20
-5 -28 -18 -28 -24 0 -32 -8 -25 -25 2 -8 11 -11 19 -8 9 3 14 -1 14 -12 0
-12 -5 -15 -20 -10 -11 4 -20 2 -20 -4 0 -6 -6 -11 -14 -11 -8 0 -18 -9 -21
-20 -8 -25 -22 -26 -30 -1 -3 11 -16 23 -28 27 -12 3 -30 13 -39 22 -16 14
-18 13 -18 -1 0 -10 7 -17 15 -17 8 0 15 -8 15 -17 0 -15 2 -16 10 -3 6 10 10
11 10 3 0 -7 7 -13 15 -13 18 0 19 -9 5 -44 -5 -14 -16 -26 -23 -26 -9 0 -13
-9 -12 -22 4 -34 5 -35 16 -9 7 17 15 22 29 18 11 -3 20 -1 20 4 0 5 -4 9 -10
9 -5 0 -10 7 -10 15 0 19 14 19 29 1 10 -12 9 -20 -4 -40 -16 -24 -16 -26 -1
-26 9 0 16 -6 14 -14 -2 -8 -1 -16 2 -17 11 -4 50 13 46 20 -3 4 1 8 8 8 8 0
11 -8 8 -19 -3 -11 -10 -17 -18 -14 -8 3 -14 1 -14 -4 0 -6 -9 -10 -20 -10
-12 0 -20 -7 -20 -16 0 -9 -6 -14 -13 -11 -18 7 -47 -12 -29 -20 6 -2 12 -12
12 -20 0 -13 -3 -13 -15 -3 -8 7 -15 23 -15 35 0 18 7 25 30 30 34 7 37 16 14
35 -13 11 -16 10 -21 -8 -3 -12 -10 -22 -15 -22 -6 0 -8 9 -5 19 3 14 -2 21
-19 25 -13 4 -24 11 -24 16 0 6 -7 10 -15 10 -8 0 -15 -4 -15 -9 0 -5 7 -11
15 -15 29 -11 16 -28 -16 -21 -22 5 -29 4 -25 -4 4 -6 13 -11 21 -11 23 0 23
-24 1 -37 -12 -7 -15 -12 -8 -13 6 0 12 -7 12 -16 0 -10 -6 -14 -14 -11 -10 4
-14 0 -13 -14 2 -23 15 -25 24 -3 3 8 12 13 21 9 8 -3 21 3 28 12 13 17 14 17
8 1 -4 -12 -1 -18 10 -18 17 0 22 -15 7 -25 -6 -3 -14 3 -20 12 -10 17 -10 17
-11 1 0 -10 5 -18 11 -18 5 0 7 -4 4 -10 -9 -15 -25 -12 -25 5 0 21 -11 18
-37 -9 -27 -28 -29 -36 -8 -36 8 0 15 -7 15 -15 0 -8 -7 -15 -15 -15 -8 0 -15
5 -15 11 0 5 -5 7 -12 3 -8 -5 -9 -2 -5 9 3 9 2 19 -3 22 -6 4 -10 1 -10 -5 0
-7 -10 -20 -22 -29 -19 -16 -19 -18 -5 -24 11 -4 17 -1 17 8 0 8 2 15 5 15 7
0 58 -32 65 -40 3 -4 13 -9 23 -13 9 -3 17 -12 17 -19 0 -10 -2 -10 -9 1 -5 8
-11 9 -15 3 -3 -6 -15 -8 -26 -5 -14 4 -20 0 -20 -11 0 -13 7 -15 30 -11 17 3
30 1 30 -5 0 -5 -6 -9 -12 -8 -8 2 -12 -5 -10 -16 3 -17 1 -18 -11 -8 -8 7
-21 11 -28 10 -8 -2 -13 5 -13 14 1 14 -2 15 -17 5 -11 -7 -19 -22 -19 -34 0
-13 -4 -23 -10 -23 -5 0 -10 -5 -10 -12 0 -9 3 -9 12 0 7 7 20 12 30 12 10 0
21 7 24 15 5 12 9 11 24 -3 10 -9 20 -24 22 -32 5 -23 34 -41 55 -34 17 5 17
5 1 -9 -10 -8 -18 -20 -18 -26 0 -7 -5 -9 -10 -6 -6 4 -8 10 -5 14 2 5 -4 16
-15 26 -18 16 -20 16 -20 2 0 -10 4 -17 9 -17 5 0 7 -9 4 -20 -3 -11 -12 -20
-19 -20 -8 0 -14 -9 -14 -20 0 -12 -7 -20 -17 -20 -9 0 -14 -3 -10 -6 10 -11
49 7 43 21 -5 14 14 25 41 25 8 0 23 10 34 23 10 12 18 17 19 11 0 -6 -6 -17
-14 -24 -8 -6 -12 -18 -9 -26 6 -17 -13 -19 -20 -1 -4 9 -8 8 -16 -3 -6 -8
-11 -10 -11 -5 -1 18 -20 -19 -20 -38 0 -11 9 -32 21 -48 23 -33 17 -53 -16
-47 -16 3 -22 1 -18 -8 2 -7 13 -11 23 -9 10 2 21 -3 24 -11 3 -9 10 -13 15
-9 5 3 12 1 16 -5 3 -5 -1 -10 -9 -10 -8 0 -18 5 -21 10 -3 6 -11 10 -16 10
-6 0 -8 -4 -5 -9 8 -12 -12 -18 -59 -17 -39 1 -41 3 -77 71 -193 362 -220 834
-72 1247 65 180 190 392 303 511 l35 37 -844 -1 -845 -1 -87 -96 c-218 -243
-444 -575 -598 -882 -87 -173 -198 -452 -238 -600 -35 -129 -35 -130 -13 -130
10 0 25 -8 32 -17 11 -15 11 -16 -1 -5 -21 18 -32 15 -21 -7 7 -13 18 -17 36
-13 21 3 27 0 32 -17 6 -22 22 -30 19 -8 -1 13 5 17 30 17 11 0 14 -6 10 -17
-4 -12 0 -10 10 5 20 27 39 29 24 2 -8 -15 -8 -20 1 -20 6 0 13 9 16 21 4 13
8 17 13 9 5 -8 12 -7 24 5 9 10 20 14 24 11 3 -4 1 -11 -7 -15 -10 -7 -10 -9
3 -14 8 -3 17 -1 20 6 4 7 4 2 2 -10 -3 -15 1 -23 10 -23 7 0 20 -12 29 -27
13 -23 15 -24 10 -5 -5 15 -2 22 8 22 10 0 13 8 10 24 -4 18 -10 23 -22 19 -9
-4 -19 -11 -23 -17 -5 -7 -8 -7 -8 2 0 7 7 15 15 18 8 4 15 13 15 21 0 11 6 8
19 -8 10 -13 16 -29 14 -36 -7 -18 19 -16 43 2 17 13 17 16 4 26 -13 9 -12 10
3 5 19 -6 23 4 7 20 -6 6 -3 10 9 10 15 0 17 -5 11 -27 -5 -15 -6 -30 -3 -32
2 -3 -12 -10 -32 -17 -20 -7 -34 -14 -31 -17 3 -3 19 0 36 5 19 7 28 7 25 1
-9 -13 -189 -103 -249 -123 -47 -16 -51 -16 -96 3 -50 22 -98 17 -145 -13 -50
-33 -44 -105 12 -141 28 -18 32 -27 38 -80 9 -83 2 -671 -8 -682 -5 -4 -13 1
-20 12 -26 47 -65 75 -104 75 -45 0 -50 -6 -93 -118 -50 -131 -114 -216 -214
-285 -18 -12 -26 -27 -26 -47 0 -24 10 -37 54 -69 90 -67 154 -160 195 -284
39 -116 85 -133 157 -57 21 22 42 40 46 40 4 0 8 -72 8 -159 0 -137 -2 -160
-16 -165 -23 -9 -15 -39 62 -234 140 -354 355 -739 579 -1037 311 -413 750
-808 1194 -1075 378 -227 823 -403 1239 -490 822 -172 1646 -91 2422 238 500
211 894 471 1259 827 491 480 849 1031 1078 1657 25 70 26 81 24 248 -1 98 3
181 8 189 8 12 19 4 58 -37 78 -82 111 -69 167 68 52 124 105 201 176 252 92
67 97 109 20 157 -76 47 -156 158 -190 266 -22 71 -49 114 -74 121 -36 9 -93
-21 -117 -61 -46 -78 -49 -59 -49 350 l0 354 30 21 c34 24 47 71 31 112 -20
52 -103 73 -172 44 -30 -12 -38 -12 -79 4 -25 10 -69 32 -98 49 -29 17 -55 31
-58 31 -20 0 -58 44 -57 66 2 48 3 50 12 36 4 -7 6 -25 3 -39 -3 -18 -1 -24 7
-19 6 4 11 2 11 -5 0 -8 4 -8 13 -2 6 6 20 10 30 10 16 0 16 -1 0 -20 -18 -19
-17 -20 7 -7 32 17 62 20 57 5 -3 -6 5 -12 16 -13 16 -1 18 1 8 7 -8 5 -12 18
-8 30 6 24 -8 29 -17 6 -10 -26 -23 -16 -18 15 3 19 8 27 16 23 6 -4 18 -8 26
-8 8 0 21 -4 29 -9 12 -8 11 -11 -5 -16 -15 -6 -13 -8 13 -12 28 -4 31 -2 22
14 -5 10 -14 18 -19 18 -6 0 -10 4 -10 9 0 5 15 6 33 2 35 -8 60 -4 52 9 -3 4
5 6 17 2 17 -4 20 -2 15 11 -4 10 -4 17 -1 17 2 0 9 -10 14 -22 9 -23 9 -23
10 2 0 20 2 22 10 10 8 -13 11 -13 22 -1 7 8 8 11 2 8 -7 -4 -14 0 -17 8 -4 9
-1 12 8 9 10 -4 7 21 -15 103 -53 199 -147 432 -270 670 -398 768 -1028 1399
-1775 1778 -670 339 -1325 477 -2070 435z m-135 -240 c0 -29 -35 -44 -54 -24
-9 8 -16 19 -16 24 0 5 7 16 16 24 19 20 54 5 54 -24z m764 14 c20 -8 21 -38
1 -54 -21 -17 -45 -5 -45 23 0 35 12 43 44 31z m-1104 -118 c0 -8 -5 -18 -11
-22 -14 -8 -33 11 -25 25 10 16 36 13 36 -3z m210 -112 c11 -12 10 -18 -3 -32
-16 -15 -18 -15 -34 0 -13 14 -14 20 -3 32 7 9 16 16 20 16 4 0 13 -7 20 -16z
m-564 -33 c-9 -14 -33 -14 -41 -1 -4 6 -1 18 6 26 11 13 14 13 27 0 8 -8 11
-19 8 -25z m245 -10 c35 -37 36 -40 18 -46 -11 -4 -29 -21 -40 -38 -10 -18
-19 -26 -19 -19 0 7 -14 26 -31 41 l-31 28 26 26 c14 14 26 31 26 37 0 18 11
11 51 -29z m1284 -31 c0 -30 -33 -40 -51 -15 -19 25 -6 47 25 43 20 -2 26 -8
26 -28z m-2116 -34 c15 -18 3 -46 -20 -46 -25 0 -35 26 -19 45 15 19 24 19 39
1z m1056 -66 c9 -15 -14 -40 -31 -33 -22 8 -18 43 5 43 11 0 23 -5 26 -10z
m-975 -155 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15 -7
15 -15z m564 2 c38 -27 -2 -95 -41 -71 -25 16 -29 55 -7 71 10 7 21 13 24 13
3 0 14 -6 24 -13z m2991 -22 c52 -25 115 -79 70 -60 -33 14 -129 17 -174 5
-87 -23 -151 -110 -151 -202 1 -90 41 -154 126 -198 l39 -21 -63 5 c-82 6
-145 43 -187 110 -41 67 -48 169 -16 238 67 141 215 192 356 123z m-1657 -176
c-2 -6 -8 -10 -13 -10 -5 0 -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z
m1872 -37 c0 -29 -16 -44 -41 -40 -30 4 -39 46 -14 65 22 15 55 0 55 -25z
m-1626 22 c22 -9 20 -20 -4 -20 -11 0 -20 6 -20 13 0 15 2 15 24 7z m210 -37
c-7 -18 -34 -24 -34 -7 0 6 7 10 16 10 11 0 14 5 9 18 -4 12 -3 14 5 6 6 -6 8
-18 4 -27z m-144 12 c0 -5 4 -8 8 -5 12 7 35 -10 38 -28 1 -9 -5 -16 -13 -16
-12 0 -13 3 -4 14 9 10 8 15 -3 19 -8 3 -20 -1 -27 -9 -10 -13 -10 -17 2 -25
8 -5 9 -9 2 -9 -6 0 -19 7 -27 16 -9 8 -16 13 -16 9 0 -3 -5 -3 -10 0 -6 4 -8
11 -4 16 4 6 11 5 20 -2 11 -9 18 -9 26 -1 9 9 8 12 -5 12 -9 0 -17 5 -17 10
0 6 7 10 15 10 8 0 15 -5 15 -11z m-450 -51 c0 -7 -7 -5 -17 4 -10 9 -24 13
-30 11 -15 -6 -17 -33 -3 -33 6 0 10 5 10 11 0 5 5 7 10 4 7 -4 6 -14 -2 -28
-7 -14 -13 -17 -16 -9 -6 18 -29 15 -45 -8 -8 -11 -17 -17 -20 -13 -11 10 -8
25 3 18 6 -3 10 1 10 10 0 9 4 14 9 11 5 -3 11 3 14 14 3 11 1 20 -4 20 -5 0
-9 -4 -9 -10 0 -5 -7 -10 -15 -10 -8 0 -15 7 -15 16 0 12 7 15 30 12 21 -3 30
0 30 9 0 11 6 11 30 -2 17 -9 30 -21 30 -27z m281 32 c5 0 9 -3 9 -7 0 -5 -11
-8 -25 -8 -14 0 -25 6 -25 14 0 8 6 11 16 7 9 -3 20 -6 25 -6z m96 -5 c9 -24
-1 -27 -15 -5 -8 13 -8 20 -2 20 6 0 13 -7 17 -15z m-515 -18 c-1 -8 2 -18 7
-22 14 -8 -8 -55 -26 -55 -17 0 -16 10 2 34 15 20 12 36 -7 36 -5 0 -8 -7 -5
-14 4 -10 1 -13 -7 -10 -20 8 -29 -12 -17 -34 14 -27 1 -36 -16 -12 -10 14
-12 25 -5 33 6 7 11 16 11 20 1 13 28 36 35 30 3 -4 6 -1 6 6 0 7 6 11 13 8 6
-2 11 -11 9 -20z m238 13 c0 -13 -38 -32 -53 -26 -7 2 -4 5 7 5 12 1 16 6 12
16 -4 10 1 15 14 15 11 0 20 -4 20 -10z m2120 1 c0 -6 -4 -13 -10 -16 -5 -3
-10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m-1906 -16 c-4 -8 -8 -15 -10
-15 -2 0 -4 7 -4 15 0 8 4 15 10 15 5 0 7 -7 4 -15z m216 5 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m245 -19 c-6 -5 -25
10 -25 20 0 5 6 4 14 -3 8 -7 12 -15 11 -17z m-419 4 c17 -16 14 -18 -10 -9
-9 3 -16 10 -16 15 0 13 9 11 26 -6z m454 5 c0 -5 -4 -10 -10 -10 -5 0 -10 5
-10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-622 -25 c-1 -13 -6 -21 -10 -17 -5
5 -6 16 -2 25 9 24 14 21 12 -8z m39 -1 c-9 -9 -28 6 -21 18 4 6 10 6 17 -1 6
-6 8 -13 4 -17z m363 22 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10
11 6 0 10 -2 10 -4z m110 0 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11
10 11 6 0 10 -2 10 -4z m-667 -40 c-10 -13 -22 -22 -26 -19 -4 3 2 13 14 24
30 26 34 24 12 -5z m47 20 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11
10 11 6 0 10 -2 10 -4z m20 -20 c0 -2 -7 -9 -15 -16 -13 -11 -14 -10 -9 4 5
14 24 23 24 12z m-160 -10 c0 -2 -8 -10 -17 -17 -16 -13 -17 -12 -4 4 13 16
21 21 21 13z m310 -7 c12 -9 12 -10 -4 -5 -11 4 -24 0 -33 -11 -13 -16 -13
-16 -3 5 12 25 18 26 40 11z m130 1 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3
6 -1 10 4 10 6 0 11 -4 11 -10z m-393 -15 c4 -4 3 -5 -4 -2 -6 4 -13 2 -15 -5
-3 -8 -6 -6 -10 4 -7 17 15 19 29 3z m94 -26 c6 -13 6 -19 -1 -19 -5 0 -10 5
-10 11 0 7 -8 18 -17 25 -17 13 -17 14 0 8 10 -4 22 -15 28 -25z m-231 9 c0
-10 2 -10 8 0 5 7 16 10 25 6 18 -7 23 -24 7 -24 -5 0 -10 5 -10 11 0 5 -4 8
-9 5 -5 -3 -7 -16 -4 -29 3 -18 1 -17 -12 7 -13 22 -19 26 -26 15 -5 -8 -9 -9
-9 -3 0 6 3 14 7 17 10 11 23 8 23 -5z m100 2 c0 -5 -2 -10 -4 -10 -3 0 -8 5
-11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m73 -5 c-3 -9 -8 -14 -10 -11 -3 3
-2 9 2 15 9 16 15 13 8 -4z m104 -17 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3
4 -12 1 -19z m2223 7 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11 12 23 8 23
-8z m-2411 -35 c0 -10 -3 -8 -9 5 -12 27 -12 43 0 25 5 -8 9 -22 9 -30z m-131
8 c9 -9 12 -9 12 0 0 7 5 12 12 12 8 0 6 -7 -5 -17 -14 -14 -19 -15 -26 -5 -4
7 -15 10 -23 7 -9 -4 -19 -2 -23 4 -9 15 38 14 53 -1z m199 -20 c-3 -8 -6 -5
-6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-261 -15 c-10 -10 -19 5 -10 18 6 11
8 11 12 0 2 -7 1 -15 -2 -18z m324 17 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10
-3 6 -1 10 4 10 6 0 11 -4 11 -10z m170 -20 c0 -13 -7 -20 -20 -20 -19 0 -27
20 -13 33 13 14 33 6 33 -13z m-262 -15 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7
-7 10 -15 7 -18z m-298 5 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11
10 2 0 4 -4 4 -10z m100 0 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4
10 6 0 11 -4 11 -10z m-140 -21 c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10 16 0 6 5
7 10 4 6 -3 10 -11 10 -16z m220 11 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0
6 5 10 10 10 6 0 10 -4 10 -10z m-156 -15 c-4 -8 -8 -15 -10 -15 -2 0 -4 7 -4
15 0 8 4 15 10 15 5 0 7 -7 4 -15z m2470 -7 c8 -14 7 -21 -6 -34 -30 -30 -75
9 -48 42 16 18 39 15 54 -8z m-2709 2 c-3 -5 4 -19 15 -30 12 -12 19 -25 15
-28 -4 -4 -11 1 -16 11 -6 9 -19 17 -30 17 -16 0 -19 -5 -14 -20 4 -11 2 -20
-4 -20 -6 0 -11 7 -11 15 0 8 -4 15 -10 15 -5 0 -10 -5 -10 -11 0 -5 -5 -7
-10 -4 -11 7 -42 -19 -35 -29 3 -3 -1 -1 -7 4 -16 14 39 62 64 56 9 -2 25 5
34 15 18 20 29 25 19 9z m45 -5 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11
12 23 8 23 -8z m2860 -15 c0 -28 -27 -45 -45 -30 -20 16 -19 37 2 48 22 13 43
4 43 -18z m-2750 10 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6
0 10 -4 10 -10z m255 -20 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10
3 0 8 -4 11 -10z m-455 -55 c9 -10 9 -15 1 -15 -6 0 -11 5 -11 11 0 8 -5 7
-15 -1 -9 -7 -19 -10 -22 -6 -4 4 -1 11 7 16 20 13 26 12 40 -5z m50 6 c0 -5
-7 -11 -15 -15 -15 -5 -20 5 -8 17 9 10 23 9 23 -2z m132 -13 c-7 -7 -12 -8
-12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m618 -7 c21 -41 -1 -81 -45 -81
-28 0 -39 27 -30 70 5 25 12 30 35 30 19 0 33 -7 40 -19z m-728 -23 c-7 -7
-12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m3048 12 c0 -5 -4 -10
-10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-3306 -15 c-6
-16 -24 -21 -24 -7 0 11 11 22 21 22 5 0 6 -7 3 -15z m153 -5 c1 -11 -2 -22
-8 -25 -5 -3 -6 0 -3 8 10 26 -13 27 -24 2 -7 -14 -18 -25 -25 -25 -7 0 -19
-16 -27 -35 -15 -37 -32 -48 -23 -15 5 21 -11 28 -22 10 -9 -15 -25 -12 -25 5
0 15 16 19 54 15 10 -1 16 3 12 8 -3 5 6 15 19 22 14 7 25 19 25 26 0 12 15
22 35 23 6 1 11 -8 12 -19z m2103 -16 c0 -17 -5 -24 -20 -24 -14 0 -20 7 -20
24 0 23 17 38 33 29 4 -2 7 -15 7 -29z m-2060 22 c0 -2 -7 -9 -15 -16 -13 -11
-14 -10 -9 4 5 14 24 23 24 12z m27 -18 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m2786 -14 c9 -10 -4 -34 -19 -34 -8 0 -14 9 -14 20 0 19 19
27 33 14z m-2855 -28 c-7 -8 -16 -12 -21 -9 -11 7 2 23 19 23 12 0 12 -2 2
-14z m-1328 0 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10
-2 10 -4z m1279 -28 c-7 -13 -14 -18 -16 -11 -3 7 2 19 9 27 18 20 22 12 7
-16z m126 22 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11
-10z m2893 -9 c18 -11 11 -51 -10 -65 -35 -22 -74 27 -48 59 14 17 38 19 58 6z
m-2978 -21 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m-138 -35 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6
-2 11 -8 11 -13z m123 -5 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10
3 0 8 -4 11 -10z m-175 -15 c-7 -9 -15 -13 -17 -11 -7 7 7 26 19 26 6 0 6 -6
-2 -15z m-47 -41 c-9 -15 -19 -22 -21 -16 -2 6 3 18 10 26 22 25 30 18 11 -10z
m3102 -14 c0 -18 -6 -26 -23 -28 -24 -4 -38 18 -28 44 3 9 15 14 28 12 17 -2
23 -10 23 -28z m-3065 11 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10
16 6 0 10 -4 10 -9z m-100 -32 c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7
10 4 6 -3 10 -11 10 -16z m995 -4 c0 -28 -29 -25 -33 3 -3 19 0 23 15 20 10
-2 18 -12 18 -23z m1884 -28 c15 -62 44 -95 99 -110 62 -19 66 -41 10 -55 -56
-14 -84 -42 -99 -95 -6 -23 -13 -48 -15 -55 -9 -30 -37 -5 -51 43 -19 65 -44
91 -104 106 -27 7 -49 18 -49 25 0 7 22 20 49 29 55 18 86 46 102 95 24 72 42
77 58 17z m-2844 23 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0
8 -4 11 -10z m-55 -10 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10
6 0 10 -4 10 -10z m-65 -10 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9
10 6 0 13 -4 16 -10z m-2155 -21 c0 -25 -33 -22 -38 4 -2 12 3 17 17 17 15 0
21 -6 21 -21z m2128 0 c11 -18 11 -23 -5 -35 -10 -7 -21 -14 -25 -14 -5 0 -8
-5 -8 -10 0 -6 -12 -22 -27 -35 l-28 -24 -22 21 c-12 12 -20 25 -17 29 8 14
24 10 24 -6 0 -8 7 -15 15 -15 8 0 15 5 15 11 0 5 4 8 8 5 4 -2 14 9 21 24 7
16 17 28 23 27 6 -1 13 4 15 11 3 6 0 12 -6 12 -6 0 -11 5 -11 10 0 17 14 11
28 -11z m62 11 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z m908 -7 c-2 -10 -10 -18 -18 -18 -8 0 -16 8 -18 18 -2 12 3 17 18
17 15 0 20 -5 18 -17z m2697 -12 c-69 -40 -141 -119 -177 -198 -40 -85 -49
-191 -24 -277 10 -32 16 -59 14 -60 -2 -1 -15 -8 -29 -15 -17 -8 -31 -27 -38
-53 -11 -34 -16 -39 -39 -38 -15 1 -29 1 -32 0 -3 -1 -13 -3 -23 -5 -10 -2
-34 -19 -53 -39 -47 -48 -49 -103 -8 -208 13 -34 24 -63 24 -66 0 -2 -12 -2
-27 -1 -50 4 -104 -1 -99 -9 6 -11 -96 -14 -113 -3 -11 7 -10 14 8 39 l21 31
-29 47 c-77 120 -61 269 39 377 65 71 169 109 242 89 l33 -9 0 86 c0 74 4 94
28 143 54 110 183 196 297 197 l35 1 -50 -29z m-3525 15 c0 -11 -19 -15 -25
-6 -3 5 1 10 9 10 9 0 16 -2 16 -4z m-40 -16 c0 -5 -4 -10 -10 -10 -5 0 -10 5
-10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-50 -20 c0 -5 -4 -10 -10 -10 -5 0
-10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m570 -4 c0 -8 -4 -18 -10 -21
-5 -3 -10 -4 -10 -1 0 2 -3 11 -6 20 -4 11 -1 16 10 16 9 0 16 -6 16 -14z
m-763 -29 c-6 -20 -27 -19 -27 2 0 14 5 19 15 14 9 -3 14 -10 12 -16z m263 3
c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z
m-110 -25 c-7 -8 -19 -15 -26 -15 -8 0 -14 -7 -14 -15 0 -8 -4 -15 -10 -15
-30 0 18 59 49 60 11 0 11 -3 1 -15z m358 -60 c6 -14 29 -37 51 -52 41 -28 51
-43 29 -43 -21 0 -77 -44 -88 -70 l-12 -24 -35 43 c-20 23 -47 47 -61 52 l-25
10 39 29 c22 16 46 40 54 54 17 33 34 33 48 1z m-448 -2 c0 -7 -7 -13 -15 -13
-8 0 -15 -5 -15 -11 0 -5 -5 -7 -11 -3 -8 5 -8 9 1 14 7 4 10 12 7 17 -3 5 3
9 14 9 10 0 19 -6 19 -13z m90 7 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6
1 10 9 10 9 0 16 -4 16 -10z m-40 -30 c0 -3 -9 -6 -20 -6 -13 0 -20 7 -20 18
0 16 3 16 20 6 11 -7 20 -15 20 -18z m35 -10 c3 -5 1 -10 -4 -10 -6 0 -11 5
-11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-65 -21 c12 -9 11 -10 -7 -6 -14 3
-24 -1 -28 -11 -3 -8 -8 -12 -12 -9 -6 7 15 37 26 37 3 0 13 -5 21 -11z m40
-9 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-26
-44 c-3 -9 -10 -13 -15 -10 -5 3 -3 11 4 19 15 16 19 13 11 -9z m-162 -18 c-7
-7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m208 12 c0 -5 -2 -10
-4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-50 -34 c0 -3 -4
-8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-2116 -110
c7 -38 29 -61 74 -77 18 -6 32 -15 32 -20 0 -5 -20 -18 -44 -30 -37 -17 -47
-28 -59 -62 -7 -23 -18 -41 -23 -39 -5 1 -15 20 -23 43 -11 34 -21 45 -57 61
-24 10 -44 21 -44 24 0 3 20 13 44 24 36 15 46 25 56 57 6 21 13 44 16 51 6
21 21 5 28 -32z m692 -22 c26 -128 72 -171 202 -189 45 -6 52 -10 52 -30 0
-23 -29 -35 -88 -35 -37 0 -104 -36 -127 -68 -11 -15 -27 -56 -35 -91 -25
-100 -60 -109 -74 -19 -4 22 -15 56 -25 76 -32 62 -61 81 -147 99 -43 8 -82
20 -86 26 -12 19 3 35 40 42 82 14 115 28 152 61 30 28 41 48 55 102 9 37 19
73 21 80 2 7 14 12 26 10 18 -2 24 -14 34 -64z m4269 5 c9 -26 -15 -52 -38
-43 -17 6 -23 44 -10 57 14 14 42 6 48 -14z m-3085 -17 c0 -13 -12 -22 -22
-16 -10 6 -1 24 13 24 5 0 9 -4 9 -8z m90 -7 c0 -8 -4 -15 -10 -15 -5 0 -7 7
-4 15 4 8 8 15 10 15 2 0 4 -7 4 -15z m2192 -37 c65 -62 168 -204 168 -232 0
-2 -32 18 -72 44 -40 27 -76 46 -80 44 -4 -3 -8 1 -8 8 0 10 -2 10 -9 -1 -12
-18 52 -105 172 -236 81 -87 98 -113 45 -67 -10 8 -24 12 -32 9 -9 -4 -18 -2
-21 4 -4 5 -12 6 -18 3 -7 -4 -9 -3 -4 1 7 9 -20 45 -34 45 -4 0 0 -18 9 -39
9 -22 21 -37 26 -34 5 4 6 1 2 -5 -4 -6 7 -36 24 -66 16 -30 30 -58 30 -63 0
-4 -21 17 -47 47 -69 78 -153 156 -214 198 -60 40 -166 147 -175 176 -5 14 17
41 87 112 52 52 96 94 100 94 3 0 26 -19 51 -42z m-3028 13 c21 -23 14 -56
-13 -71 -42 -22 -85 55 -43 79 25 15 37 13 56 -8z m797 1 c-7 -2 -18 1 -23 6
-8 8 -4 9 13 5 13 -4 18 -8 10 -11z m24 -32 c3 -5 1 -10 -4 -10 -6 0 -11 5
-11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m1763 -128 c30 -31 91 -99 135 -151 67
-79 76 -92 47 -74 -88 53 -106 63 -115 63 -12 0 27 -34 218 -186 86 -70 157
-128 157 -130 0 -7 -50 20 -152 81 -50 30 -94 55 -98 55 -10 0 55 -98 153
-227 64 -86 73 -103 56 -103 -14 0 -91 69 -224 201 -112 111 -216 210 -232
220 -15 10 -57 21 -93 25 l-64 7 44 -54 c25 -30 83 -94 130 -144 158 -168 166
-178 141 -164 -70 41 -142 92 -165 118 -21 23 -46 30 -27 8 8 -11 6 -13 -9
-12 -10 1 -28 12 -39 26 -11 13 -21 20 -21 14 0 -5 -6 1 -13 15 -8 14 -19 24
-25 23 -7 -2 -11 1 -9 6 1 5 0 8 -3 8 -3 -1 -23 18 -45 42 -22 25 -53 57 -70
73 -33 30 -152 93 -200 105 -28 7 -27 8 15 10 149 9 143 7 217 96 37 45 87
103 111 129 l45 48 41 -36 c22 -20 65 -62 94 -92z m-1778 104 c0 -3 -4 -8 -10
-11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m590 -46 c0 -26 -36
-45 -56 -29 -20 16 -18 57 4 62 25 5 52 -11 52 -33z m-676 14 c9 -3 13 -10 10
-15 -8 -14 -21 -11 -28 6 -6 17 -4 18 18 9z m56 -4 c0 -5 -2 -10 -4 -10 -3 0
-8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m40 -10 c0 -5 -4 -10 -10 -10
-5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m70 -4 c0 -3 -4 -8 -10
-11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-2227 -55 c39 -70
37 -81 -13 -69 -32 8 -45 7 -65 -6 -22 -15 -25 -15 -25 -1 0 36 50 125 70 125
4 0 19 -22 33 -49z m2107 39 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
10 10 6 0 10 -4 10 -10z m4508 -61 c25 -41 21 -49 -20 -49 -16 0 -40 -7 -53
-16 -29 -19 -32 -9 -10 43 19 44 46 77 55 66 4 -5 16 -24 28 -44z m-4588 -1
c0 -4 -4 -8 -10 -8 -5 0 -10 5 -10 10 0 6 -5 10 -11 10 -6 0 -9 -7 -6 -15 7
-17 -8 -29 -23 -20 -8 5 -7 11 1 21 6 8 8 14 3 14 -4 0 1 7 12 15 17 12 20 12
32 -2 6 -9 12 -20 12 -25z m36 15 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7
1 -15 -2 -18z m3113 -7 c15 -18 3 -46 -20 -46 -25 0 -35 26 -19 45 15 19 24
19 39 1z m-3082 -12 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m-1663 -25 c17 -24 12 -45 -14 -59 -25 -14 -49 -2 -56 26 -12 48 41 73 70 33z
m1624 -24 c-6 -14 -18 -25 -25 -25 -8 0 -12 4 -8 9 3 5 0 11 -7 14 -7 2 -3 2
10 0 15 -2 22 2 22 12 0 8 4 15 10 15 6 0 5 -10 -2 -25z m-62 -33 c-22 -5 -30
3 -21 18 5 8 11 7 22 -2 15 -12 14 -13 -1 -16z m84 -23 c0 -6 -4 -7 -10 -4 -5
3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m-190 -34 c0 -8 -4 -15 -9
-15 -13 0 -22 16 -14 24 11 11 23 6 23 -9z m-1861 -109 c2 -90 -5 -136 -21
-136 -5 0 -6 5 -3 10 3 6 -1 10 -10 10 -14 0 -16 12 -13 90 l3 90 -45 5 c-78
11 -77 13 6 14 l81 1 2 -84z m1855 75 c3 -4 -6 -13 -19 -19 -20 -9 -25 -9 -25
2 0 20 34 34 44 17z m96 -17 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11
15 -7z m60 6 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4
11 -10z m4477 4 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-6590
-66 c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z m6683 -43 c0 -87
-3 -105 -15 -105 -17 0 -17 2 -17 120 0 100 -2 90 17 90 12 0 15 -18 15 -105z
m-68 3 c2 -51 0 -97 -4 -102 -5 -6 -9 19 -10 55 -1 35 -2 81 -4 102 -1 20 2
37 6 37 5 0 10 -42 12 -92z m-4582 78 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4
0 6 5 11 10 11 6 0 10 -2 10 -4z m44 -11 c-7 -16 -24 -21 -24 -6 0 8 7 13 27
20 1 1 0 -6 -3 -14z m-41 -35 c-3 -11 -9 -20 -14 -20 -14 0 -11 28 4 33 6 3
13 6 14 6 0 1 -1 -8 -4 -19z m1449 -9 c75 -29 152 -99 189 -172 16 -32 29 -60
29 -63 0 -2 -26 13 -57 34 -124 82 -232 88 -370 21 -76 -37 -134 -82 -88 -68
173 52 311 24 434 -90 l55 -50 -45 -7 c-89 -13 -212 -59 -283 -106 -77 -51
-120 -94 -209 -210 -147 -194 -270 -285 -446 -332 -62 -16 -118 -21 -301 -25
-124 -3 -223 -3 -220 0 3 3 50 28 105 56 265 134 409 297 479 546 70 244 205
406 396 473 87 30 245 27 332 -7z m2638 -1 c24 -24 24 -30 7 -54 -9 -12 -23
-17 -42 -14 -25 2 -31 8 -33 33 -3 19 2 33 14 42 24 17 30 17 54 -7z m-5439
-19 c106 -41 181 -115 228 -224 l26 -63 30 12 c68 29 182 5 246 -53 l40 -36
54 13 c129 31 273 -28 338 -139 18 -31 18 -31 -5 -33 -48 -3 -84 5 -123 25
-81 43 -242 21 -311 -44 l-21 -20 -12 46 c-15 61 -82 129 -143 145 -41 12 -97
9 -145 -6 -18 -5 -23 -2 -23 12 0 34 -48 122 -91 169 -76 83 -182 123 -348
133 l-105 7 62 33 c34 18 81 37 104 42 55 11 140 3 199 -19z m729 4 c0 -8 16
-27 35 -42 19 -15 35 -31 35 -35 0 -4 -13 -14 -29 -22 -15 -8 -34 -30 -42 -47
l-13 -33 -13 33 c-8 18 -27 40 -43 49 -36 21 -37 29 -5 44 13 6 31 26 40 45
16 32 35 37 35 8z m646 10 c-4 -10 2 -19 17 -25 16 -6 17 -9 5 -9 -9 -1 -19 3
-23 9 -3 5 -13 6 -22 3 -13 -5 -13 -3 3 14 23 26 28 28 20 8z m34 -6 c0 -5 -4
-9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m-100 7 c0
-17 -15 -36 -27 -36 -8 0 -13 -6 -10 -13 8 -20 -17 -47 -33 -37 -12 7 -12 10
3 16 9 3 17 15 17 26 0 15 -5 18 -20 13 -14 -5 -20 -2 -20 9 0 19 13 20 41 6
15 -8 19 -7 19 5 0 8 7 15 15 15 8 0 15 -2 15 -4z m24 -35 c3 -5 -1 -11 -9
-14 -9 -4 -12 -1 -8 9 6 16 10 17 17 5z m45 -20 c9 -6 6 -8 -13 -8 -14 0 -23
3 -20 8 7 11 15 11 33 0z m-62 -41 c2 -2 8 2 13 10 6 8 10 11 10 5 0 -5 -5
-17 -11 -25 -8 -11 -12 -12 -16 -2 -6 15 -33 16 -33 0 0 -6 -2 -9 -5 -6 -3 2
1 16 8 31 10 22 13 24 21 9 5 -9 11 -19 13 -22z m103 19 c0 -5 -4 -7 -10 -4
-5 3 -10 1 -10 -6 0 -7 -3 -10 -6 -6 -8 7 4 27 17 27 5 0 9 -5 9 -11z m-912
-72 c3 -23 0 -27 -23 -27 -30 0 -43 24 -25 46 19 22 45 13 48 -19z m907 23 c3
-5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m-115
-15 c0 -8 -4 -15 -9 -15 -13 0 -22 16 -14 24 11 11 23 6 23 -9z m38 -14 c-8
-12 -18 -18 -21 -14 -4 3 1 14 11 23 22 23 28 17 10 -9z m-58 -15 c0 -3 -4 -8
-10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m3495 -30 c39
-15 101 -45 138 -67 73 -43 215 -177 262 -247 l28 -43 -19 -26 c-11 -15 -24
-28 -30 -29 -5 -1 -29 21 -54 50 -101 116 -243 215 -369 258 -183 61 -431 -5
-607 -161 -148 -132 -239 -207 -304 -252 -90 -62 -171 -111 -177 -106 -2 3 18
23 44 46 27 22 93 91 148 153 235 266 317 339 452 400 104 47 164 58 298 54
101 -2 131 -7 190 -30z m-3601 9 c3 -9 0 -15 -9 -15 -8 0 -15 7 -15 15 0 8 4
15 9 15 5 0 11 -7 15 -15z m131 5 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6
11 10 16 10 6 0 7 -4 4 -10z m-1527 -45 c12 -25 22 -49 22 -54 0 -5 -17 -7
-37 -5 -20 2 -43 -2 -50 -8 -7 -6 -16 -8 -19 -5 -6 6 46 117 56 117 3 0 16
-20 28 -45z m1482 26 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16
6 0 10 -4 10 -9z m47 -21 c1 -11 -2 -20 -7 -20 -10 0 -20 26 -13 34 12 11 19
6 20 -14z m-27 -9 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0
10 -4 10 -9z m-1973 -38 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z
m6694 10 c2 -54 2 -129 1 -180 -2 -40 -6 -53 -17 -53 -13 0 -15 21 -15 130 0
109 2 130 15 130 9 0 15 -10 16 -27z m-4661 -3 c0 -5 -4 -10 -10 -10 -5 0 -10
5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m4587 -167 c-2 -32 -3 -8 -3 52 0
61 1 87 3 58 2 -29 2 -78 0 -110z m-1259 80 c12 -22 -2 -63 -21 -63 -35 0 -50
52 -19 69 24 14 30 13 40 -6z m-3328 3 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10
4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-2031 -129 c1 -37 -3 -56 -9 -52 -6 3 -9
43 -9 88 2 93 16 64 18 -36z m1960 104 c12 -8 12 -12 2 -25 -13 -15 -45 -11
-35 5 3 5 10 7 15 4 5 -4 9 -2 9 4 0 5 -6 12 -12 14 -7 3 -8 6 -3 6 6 0 16 -3
24 -8z m51 -35 c0 -6 -7 -1 -15 10 -8 10 -15 23 -15 28 0 6 7 1 15 -10 8 -10
15 -23 15 -28z m-2227 0 c4 -10 1 -13 -8 -9 -8 3 -12 9 -9 14 7 12 11 11 17
-5z m682 -6 c13 -6 15 -9 5 -9 -8 -1 -21 -10 -28 -21 -7 -11 -19 -20 -26 -20
-16 0 -31 34 -21 50 7 12 42 12 70 0z m6481 -35 c4 -26 -13 -16 -19 11 -3 18
-1 22 6 15 6 -6 12 -18 13 -26z m154 25 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10
-3 6 -1 10 4 10 6 0 11 -4 11 -10z m-218 -12 c-8 -8 -9 -15 -1 -25 8 -9 8 -13
1 -13 -6 0 -13 4 -16 9 -8 13 4 41 17 41 8 0 7 -4 -1 -12z m-7077 -8 c3 -5 1
-10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m295 -65 c0 -63
-1 -64 -22 -56 -15 5 -24 18 -26 36 -5 37 6 77 18 70 6 -3 10 1 10 10 0 9 5
13 10 10 6 -3 10 -35 10 -70z m1880 65 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10
10 0 6 5 10 10 10 6 0 10 -4 10 -10z m23 -5 c-3 -9 -8 -14 -10 -11 -3 3 -2 9
2 15 9 16 15 13 8 -4z m-2136 -17 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m4242 12 c91 -12 254 -60 286 -85 20 -15 19 -16 -14 -44 l-34 -30
-51 21 c-157 64 -366 88 -581 68 -164 -15 -215 -25 -290 -55 l-60 -24 30 25
c77 64 170 94 355 113 58 6 119 13 135 15 65 7 144 6 224 -4z m2806 0 c3 -5 1
-10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-7165 -10 c0
-5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m1260
-30 c0 -13 -7 -20 -20 -20 -13 0 -20 7 -20 20 0 13 7 20 20 20 13 0 20 -7 20
-20z m967 13 c-4 -3 -2 -12 4 -19 8 -9 5 -11 -10 -7 -24 6 -27 19 -8 26 18 8
21 8 14 0z m2961 -37 c5 -26 -26 -50 -45 -35 -14 12 -17 41 -6 53 13 13 48 1
51 -18z m1522 -37 c0 -6 7 -9 15 -5 8 3 15 1 15 -5 0 -6 5 -7 10 -4 6 3 10 -3
10 -14 0 -23 -106 -111 -134 -111 -11 0 -45 5 -77 12 -76 16 -165 -6 -271 -68
-82 -47 -168 -87 -168 -78 0 15 86 128 127 169 72 69 141 99 239 103 44 2 98
-1 119 -7 22 -6 42 -7 45 -2 4 8 15 11 63 20 4 0 7 -4 7 -10z m-6000 -114 l0
-116 -29 15 c-16 9 -31 26 -35 38 -7 29 -8 106 0 134 5 19 34 41 57 43 4 1 7
-51 7 -114z m6245 18 c-4 -32 -7 -65 -7 -73 0 -30 -21 -100 -29 -100 -5 0 -9
52 -9 115 l0 115 26 0 c27 0 27 0 19 -57z m-83 0 c-6 -65 -12 -67 -12 -5 0 29
4 52 8 52 5 0 7 -21 4 -47z m236 -28 c-12 -40 -12 -40 -16 -10 -2 16 1 31 7
33 5 2 7 11 4 20 -4 12 -3 14 5 7 9 -8 9 -21 0 -50z m-6991 23 c-3 -8 -6 -5
-6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m7049 3 c-3 -4 2 -17 10 -27 9 -10 13
-14 10 -8 -3 7 2 14 12 16 15 4 17 0 13 -26 -2 -17 -6 -25 -9 -18 -3 10 -6 10
-16 -1 -21 -28 -50 -29 -62 -3 -9 20 -9 23 2 19 8 -3 14 0 14 7 0 6 5 8 10 5
6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5 3 -10 1 -10 -4 0 -6 -4 -11 -10 -11 -19
0 -10 21 13 30 29 11 29 11 23 1z m-3118 -16 c87 -21 163 -58 219 -109 l47
-43 -39 -20 -39 -20 -101 49 c-125 61 -209 76 -329 58 -105 -15 -197 -44 -274
-86 -34 -19 -65 -34 -69 -34 -13 0 35 44 94 86 158 113 334 156 491 119z
m-3930 -30 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m1946 15
c55 -6 133 -19 174 -30 70 -19 222 -83 222 -93 0 -8 -124 4 -195 20 -38 8
-133 17 -210 20 -198 8 -327 -20 -575 -122 -63 -26 -150 -60 -192 -76 -43 -15
-85 -32 -95 -37 -10 -6 -21 -10 -26 -11 -4 0 -30 -7 -59 -16 -76 -22 -103 -19
-53 7 22 11 117 62 210 113 305 167 451 217 685 233 8 1 60 -3 114 -8z m5172
-25 c-3 -8 2 -11 13 -8 25 6 45 -21 32 -45 -8 -15 -20 -19 -51 -17 -48 3 -70
19 -52 37 9 9 15 9 24 0 9 -9 9 -12 -1 -12 -7 0 -9 -4 -6 -10 3 -5 11 -10 16
-10 6 0 7 5 3 12 -5 8 1 9 22 5 25 -5 30 -2 31 16 1 12 -1 17 -5 11 -4 -5 -18
-11 -30 -11 -17 -1 -22 2 -18 13 3 9 6 22 6 30 0 9 5 12 11 9 6 -4 8 -13 5
-20z m-7176 0 c-7 -9 -15 -13 -19 -10 -3 3 1 10 9 15 21 14 24 12 10 -5z
m-200 1 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10
-4z m155 -25 c-8 -14 -35 -14 -35 0 0 5 4 8 9 4 5 -3 13 0 16 6 4 5 9 8 12 5
3 -2 2 -9 -2 -15z m6915 -215 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5
11 10 11 6 0 10 -2 10 -4z m-6959 -198 c65 -101 195 -307 290 -458 95 -151
188 -301 208 -332 20 -32 38 -58 41 -58 3 0 4 163 2 362 l-3 363 -25 35 -24
35 172 5 c95 3 175 4 177 3 1 -1 -9 -17 -23 -36 l-26 -34 0 -630 0 -630 -37
-7 c-21 -3 -69 -6 -108 -6 l-70 0 -85 138 c-47 75 -151 246 -230 378 -80 133
-146 241 -147 240 -2 -1 -3 -174 -3 -384 -1 -210 -4 -382 -8 -382 -4 0 -6 -17
-4 -39 2 -23 -1 -42 -8 -46 -14 -9 -32 14 -23 29 3 5 1 6 -5 2 -7 -4 -12 -14
-12 -23 0 -13 -3 -13 -16 -2 -13 10 -17 10 -25 -2 -7 -10 -8 -5 -5 16 l6 30
-12 -27 c-12 -28 -50 -42 -67 -25 -8 8 -11 7 -11 -2 0 -7 -4 -9 -10 -6 -5 3
-10 1 -10 -5 0 -7 6 -10 14 -7 16 6 36 -11 36 -32 0 -11 -4 -11 -20 4 -19 17
-20 17 -31 -9 -7 -14 -16 -26 -21 -26 -4 0 -8 397 -8 882 0 485 3 878 7 875 3
-4 59 -89 124 -189z m7343 -1440 c-16 -37 -28 -46 -37 -30 -2 4 -3 2 -1 -3 1
-6 -4 -19 -11 -28 -12 -16 -13 -16 -19 -1 -3 9 -13 14 -21 10 -8 -3 -17 -1
-21 5 -3 6 -12 8 -20 5 -7 -3 -18 3 -23 12 -10 16 -10 16 -11 0 0 -9 6 -18 13
-21 6 -2 9 -7 5 -11 -3 -4 -16 -3 -27 4 -14 7 -21 7 -21 0 0 -5 -5 -10 -11
-10 -7 0 -9 131 -7 425 2 234 2 425 1 425 -1 0 -11 -30 -23 -67 -38 -118 -222
-637 -232 -657 -10 -19 -11 -19 -21 0 -6 10 -66 181 -134 379 l-122 360 -3
-370 -3 -370 -132 -3 c-130 -3 -149 2 -118 28 13 11 15 93 15 624 l0 611 -25
37 c-14 20 -25 38 -25 40 0 2 64 2 141 2 126 -1 143 -3 152 -20 12 -20 58
-126 157 -359 97 -228 104 -245 110 -245 3 0 37 67 77 148 39 81 95 188 124
237 81 142 177 303 233 390 l51 80 3 -795 c2 -725 1 -798 -14 -832z m-3710
1470 c-7 -158 -12 -844 -7 -912 l6 -69 29 64 c16 35 49 120 74 189 25 69 88
243 140 386 l96 262 69 6 c39 4 93 4 122 1 l52 -7 219 -656 c120 -362 221
-663 223 -669 4 -10 -26 -13 -125 -13 l-131 0 -19 53 c-11 28 -32 91 -47 139
-16 48 -33 88 -39 90 -6 2 -106 2 -222 0 l-211 -3 -21 -67 c-11 -37 -36 -112
-56 -167 -20 -55 -36 -105 -36 -111 0 -5 18 -40 40 -77 22 -36 40 -70 40 -74
0 -4 -14 -16 -31 -26 l-31 -17 -96 54 c-54 30 -140 69 -192 87 -52 17 -98 36
-102 43 -4 6 -8 145 -8 309 l0 297 -171 0 c-136 0 -169 -3 -162 -12 20 -27
265 -559 260 -564 -6 -6 -254 10 -261 17 -2 2 -55 108 -117 234 l-114 230 -5
-215 -5 -215 -132 -3 c-73 -1 -133 1 -133 5 0 4 7 17 15 27 13 17 15 100 15
559 l0 540 -104 106 -105 106 242 -3 c133 -2 243 -5 245 -7 3 -2 -5 -16 -17
-31 -20 -26 -21 -36 -21 -360 0 -184 3 -334 8 -333 4 0 40 38 81 85 l74 84
204 0 203 0 0 278 0 278 33 31 c45 43 138 110 167 122 14 5 35 10 46 10 21 1
22 -3 18 -81z m1405 -74 l31 -6 2 -262 c2 -170 6 -254 12 -241 9 18 313 412
368 476 23 27 24 27 201 31 l178 5 -74 -76 c-103 -105 -417 -489 -417 -510 0
-5 35 -60 77 -123 117 -173 363 -528 400 -577 18 -24 33 -48 33 -52 0 -5 -67
-9 -148 -9 -141 0 -149 1 -169 23 -28 31 -134 183 -308 442 -81 121 -150 222
-152 224 -8 10 3 -645 11 -666 l8 -23 -151 0 c-122 0 -151 3 -151 14 0 8 7 16
15 20 13 5 15 83 15 624 0 604 0 620 -20 645 -11 14 -20 30 -20 36 0 12 204
16 259 5z m1191 -4 c-1 -3 -7 -16 -15 -30 -12 -21 -14 -127 -15 -647 0 -404 4
-624 10 -628 6 -3 10 -13 10 -21 0 -11 -25 -14 -145 -14 -80 0 -145 3 -145 8
0 4 7 16 15 26 13 17 15 109 15 631 l0 610 -25 38 -25 37 160 -3 c88 -1 160
-4 160 -7z m-5004 -31 l-26 -31 0 -470 c0 -524 -1 -517 68 -560 71 -44 153
-26 206 46 20 27 21 40 24 537 l3 509 124 0 125 0 0 -517 c0 -559 -2 -578 -54
-667 -75 -129 -243 -196 -422 -168 -142 22 -234 89 -293 212 l-26 55 -3 310
c-1 171 -3 415 -5 543 l-2 232 153 0 154 0 -26 -31z m966 2 c63 -24 147 -94
184 -152 79 -125 89 -323 22 -453 -35 -68 -103 -132 -173 -161 l-64 -28 60
-118 c32 -66 91 -178 129 -250 39 -72 70 -134 70 -139 0 -4 -61 -7 -136 -6
l-136 1 -149 295 -149 295 0 83 0 82 110 0 c71 0 122 5 143 14 98 41 125 172
54 268 -32 43 -84 58 -202 58 l-105 0 0 121 0 121 143 -4 c117 -3 152 -8 199
-27z m2476 -587 c41 -28 18 -104 -31 -104 -51 0 -74 63 -37 100 24 24 38 25
68 4z m-4661 -840 c-3 -4 -2 -15 3 -24 7 -13 6 -20 -4 -23 -9 -4 -15 4 -18 24
-3 21 0 29 10 29 9 0 12 -3 9 -6z m47 -15 c-12 -13 -20 -16 -22 -9 -3 7 2 16
9 21 25 16 32 9 13 -12z m7176 11 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8
10 11 10 2 0 4 -4 4 -10z m-5000 -4 c0 -2 -44 -9 -97 -15 -54 -7 -163 -25
-243 -41 -151 -30 -353 -65 -430 -74 -206 -25 -1162 -171 -1309 -201 -14 -2
-28 -9 -31 -16 -5 -7 -13 -5 -24 7 -15 14 -19 15 -27 3 -8 -13 -10 -12 -13 10
0 5 -5 13 -10 18 -5 4 -6 3 -2 -4 3 -6 1 -15 -4 -18 -7 -5 -5 -12 7 -21 10 -7
13 -14 6 -14 -6 0 -19 10 -27 22 -11 16 -16 18 -17 8 0 -8 -5 -2 -10 15 -20
69 36 165 128 218 6 4 -2 -17 -18 -45 -27 -51 -40 -128 -20 -128 4 0 64 11
132 25 395 81 1272 210 1589 235 227 17 420 25 420 16z m3075 -26 c375 -20
731 -57 1065 -111 211 -33 649 -114 701 -129 18 -6 36 -10 38 -10 2 0 1 23 -2
52 -4 32 -18 71 -36 100 l-30 48 30 -15 c47 -25 106 -90 114 -125 8 -37 -1
-96 -17 -106 -7 -5 -8 -2 -3 6 5 8 -5 4 -21 -7 -19 -14 -35 -18 -44 -13 -8 4
-32 11 -52 14 -21 3 -57 10 -80 15 -207 47 -760 125 -1168 166 -363 37 -955
55 -1165 37 -232 -20 -490 -90 -642 -174 -57 -31 -87 -41 -136 -45 l-62 -4 35
32 c92 83 253 166 402 208 74 21 271 57 343 64 91 8 553 6 730 -3z m-5325 -10
c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m3062
-14 c161 -20 229 -34 323 -63 84 -26 243 -100 284 -132 l24 -18 -36 -27 -35
-27 -54 30 c-184 103 -475 171 -733 171 -162 0 -553 -43 -640 -70 -16 -6 -50
-14 -75 -19 -25 -4 -144 -34 -265 -66 -121 -31 -231 -59 -245 -61 -36 -5 -68
-12 -104 -22 -70 -21 -132 -8 -83 16 100 51 404 152 608 201 143 35 383 77
509 90 138 14 397 12 522 -3z m2563 -310 c121 -33 231 -70 345 -116 374 -152
496 -186 794 -225 245 -31 247 -32 203 -73 -22 -21 -25 -21 -112 -8 -50 8
-128 19 -175 26 -192 26 -292 54 -555 151 -271 100 -301 111 -412 144 -245 75
-483 82 -636 21 l-59 -25 28 30 c34 35 116 77 187 95 82 21 281 11 392 -20z
m-3050 -13 c81 -19 191 -75 252 -126 55 -46 133 -144 133 -167 0 -8 -32 -44
-70 -78 l-71 -64 -71 17 c-93 23 -245 16 -337 -16 -119 -41 -328 -177 -448
-292 l-62 -60 -10 26 c-5 14 -24 83 -41 153 -34 137 -79 270 -103 305 -9 12
-13 28 -10 36 10 25 153 126 249 175 204 105 405 136 589 91z m-1369 -68 c163
-43 299 -137 385 -269 49 -74 75 -143 106 -281 53 -234 132 -390 275 -540 97
-102 187 -167 311 -225 206 -97 428 -130 633 -95 192 33 306 85 550 248 355
238 520 314 824 378 138 30 389 32 525 5 98 -19 247 -60 304 -83 196 -80 278
-129 373 -219 36 -35 70 -64 76 -64 5 0 8 -10 7 -22 -1 -13 -3 -36 -4 -52 -1
-22 -5 -27 -18 -23 -10 2 -72 20 -138 40 -191 56 -225 61 -425 61 -154 0 -203
-4 -290 -23 -229 -49 -429 -143 -622 -293 -274 -213 -312 -240 -414 -291 -226
-114 -463 -194 -642 -217 -103 -13 -317 -17 -404 -7 -284 34 -604 157 -833
322 -103 73 -158 120 -247 210 -158 158 -205 240 -232 403 -8 47 13 37 45 -21
26 -49 62 -93 41 -52 -22 44 -51 212 -49 285 2 64 -2 83 -26 132 -33 67 -90
121 -166 157 -49 23 -68 26 -151 25 -72 -1 -109 -6 -152 -23 -72 -28 -163 -88
-239 -158 -59 -55 -70 -58 -89 -23 -8 14 -2 29 27 71 20 30 44 62 54 72 10 11
15 25 13 32 -3 7 0 16 7 21 8 4 10 3 5 -4 -4 -7 -4 -12 1 -12 16 0 32 16 31
30 -1 8 0 26 1 40 2 23 3 23 10 5 5 -12 4 -23 -3 -27 -6 -4 -8 -9 -5 -12 2 -3
20 11 39 31 19 20 28 33 20 29 -8 -4 -4 2 10 13 14 11 33 27 43 35 10 10 17
11 17 5 0 -8 4 -9 13 0 8 6 18 7 22 3 5 -4 4 -1 0 7 -6 11 -4 13 8 8 10 -3 17
-2 17 3 0 33 -130 -20 -227 -91 -32 -24 -78 -62 -101 -86 -67 -67 -81 -71
-109 -37 -20 25 -125 176 -146 211 -13 21 147 159 286 246 204 128 450 174
653 122z m2964 -65 c124 -25 246 -64 362 -117 105 -48 244 -126 282 -159 l28
-25 -22 -24 -23 -24 -51 30 c-174 102 -363 181 -538 225 -140 35 -435 45 -573
20 -181 -34 -350 -97 -495 -186 -36 -22 -72 -43 -80 -46 -8 -4 -27 -16 -42
-26 -32 -22 -52 -24 -41 -3 18 32 172 160 243 201 127 75 293 129 465 153 111
16 364 6 485 -19z m1515 -159 c97 -25 238 -92 337 -159 110 -75 535 -448 546
-480 4 -12 -284 -312 -299 -312 -5 0 -18 11 -31 24 -65 66 -127 135 -152 169
-16 21 -31 36 -33 33 -8 -8 107 -234 140 -275 9 -11 2 -23 -38 -57 -27 -24
-54 -44 -59 -44 -14 0 -185 176 -238 246 -26 33 -68 101 -95 149 -63 116 -144
203 -225 242 -138 67 -293 75 -418 23 -45 -18 -47 -18 -120 0 -41 10 -92 21
-113 25 -36 6 -38 8 -32 33 19 75 152 241 243 304 56 39 150 77 232 94 88 18
253 11 355 -15z m-2830 -322 c74 -13 215 -57 215 -67 0 -4 -20 -15 -45 -25
-41 -17 -48 -18 -97 -3 -84 24 -226 38 -308 31 -207 -19 -426 -112 -573 -242
-41 -37 -60 -38 -65 -6 -4 27 99 119 217 194 184 116 425 159 656 118z m1195
-39 c51 -10 118 -27 148 -36 63 -20 60 -22 -54 -48 -65 -15 -80 -15 -175 0
-183 28 -399 7 -599 -58 -151 -49 -369 -165 -625 -333 -91 -59 -131 -85 -160
-101 -84 -46 -127 -66 -132 -60 -8 7 -17 0 97 81 52 38 163 119 245 180 341
256 549 346 920 399 57 8 242 -5 335 -24z m-2942 -85 c47 -20 79 -50 103 -97
21 -42 28 -101 7 -63 -50 92 -154 126 -271 91 -55 -17 -158 -88 -211 -146 -41
-46 -61 -47 -72 -4 -6 25 1 35 69 100 71 68 129 107 187 125 44 13 149 10 188
-6z m4402 -171 c41 -17 80 -59 80 -88 0 -12 -11 -8 -47 18 -27 18 -67 43 -90
55 -24 12 -43 24 -43 26 0 11 62 4 100 -11z m-352 -469 c120 -20 285 -74 389
-128 95 -48 203 -124 203 -142 0 -6 -12 -17 -26 -23 -22 -11 -33 -8 -92 27
-80 47 -208 93 -352 127 -133 31 -399 44 -506 24 -210 -39 -427 -141 -664
-311 -148 -107 -407 -260 -516 -305 -48 -20 -49 -21 56 57 90 66 153 117 370
299 270 225 481 335 718 375 107 18 309 18 420 0z"
        />
        <path
          d="M6581 6593 c7 -12 15 -20 18 -17 3 2 -3 12 -13 22 -17 16 -18 16 -5
-5z"
        />
        <path
          d="M8870 5840 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"
        />
        <path d="M8846 3987 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z" />
        <path d="M8895 3959 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />
        <path d="M8846 3943 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
        <path
          d="M5707 4762 c-37 -108 -67 -203 -67 -209 0 -10 35 -13 141 -13 l140 0
-6 23 c-32 106 -130 397 -135 397 -3 0 -35 -89 -73 -198z"
        />
        <path d="M8846 3641 c-3 -5 1 -11 9 -15 8 -3 15 -1 15 4 0 13 -18 22 -24 11z" />
        <path
          d="M8040 2706 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"
        />
        <path
          d="M8080 2676 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"
        />
        <path
          d="M8123 2642 c4 -6 22 -21 39 -34 17 -12 28 -17 25 -10 -4 6 -22 21
-39 34 -17 12 -28 17 -25 10z"
        />
        <path d="M5676 8292 c-2 -4 -1 -14 5 -22 7 -12 9 -10 9 8 0 23 -5 28 -14 14z" />
        <path
          d="M5580 8270 c0 -5 7 -7 16 -3 9 3 21 -3 30 -15 14 -20 54 -27 54 -9 0
5 -7 7 -15 3 -9 -3 -23 3 -32 14 -18 21 -53 27 -53 10z"
        />
        <path
          d="M5381 8261 c-7 -5 -9 -11 -4 -14 5 -3 15 -2 22 2 7 5 9 11 4 14 -5 3
-15 2 -22 -2z"
        />
        <path
          d="M5809 8256 c6 -7 7 -16 3 -21 -4 -4 -1 -6 7 -3 23 7 24 25 1 31 -16
4 -19 2 -11 -7z"
        />
        <path
          d="M5556 8245 c4 -8 10 -15 15 -15 4 0 6 7 3 15 -4 8 -10 15 -15 15 -4
0 -6 -7 -3 -15z"
        />
        <path
          d="M5484 8235 c22 -17 36 -19 36 -6 0 10 -24 21 -43 21 -7 0 -3 -7 7
-15z"
        />
        <path d="M5860 8235 c7 -9 15 -13 17 -11 7 7 -7 26 -19 26 -6 0 -6 -6 2 -15z" />
        <path
          d="M5420 8230 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
0 -17 -5 -25 -10z"
        />
        <path
          d="M6004 8229 c-3 -6 -24 -9 -46 -7 -31 3 -36 1 -24 -8 9 -6 16 -16 16
-23 0 -6 8 -11 18 -10 10 0 12 3 5 6 -7 2 -13 9 -13 14 0 15 22 10 36 -8 12
-17 13 -17 10 2 -2 11 4 25 13 32 10 7 11 12 4 13 -6 0 -15 -5 -19 -11z"
        />
        <path d="M5345 8221 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z" />
        <path d="M5206 8205 c-3 -8 -1 -15 4 -15 13 0 22 18 11 24 -5 3 -11 -1 -15 -9z" />
        <path d="M5316 8203 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
        <path
          d="M5388 8213 c6 -2 10 -9 6 -14 -3 -5 0 -9 5 -9 6 0 11 -5 11 -10 0 -6
-11 -8 -25 -4 -20 5 -25 2 -25 -12 0 -15 2 -16 9 -5 8 12 12 13 26 1 14 -11
17 -10 22 8 3 12 10 22 17 22 6 0 -2 7 -18 15 -15 8 -31 15 -35 14 -3 0 0 -3
7 -6z"
        />
        <path
          d="M5640 8205 c-11 -13 -7 -15 23 -15 22 0 37 6 41 15 7 18 -10 20 -18
3 -5 -10 -7 -10 -12 0 -8 16 -19 15 -34 -3z"
        />
        <path
          d="M5890 8210 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M5255 8200 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path d="M6097 8191 c-4 -17 -3 -21 5 -13 5 5 8 16 6 23 -3 8 -7 3 -11 -10z" />
        <path
          d="M5926 8185 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0
-7 -7 -4 -15z"
        />
        <path
          d="M5340 8184 c0 -6 -12 -15 -27 -21 -16 -6 -21 -11 -12 -12 20 -1 56
25 46 35 -4 4 -7 3 -7 -2z"
        />
        <path
          d="M5192 8168 c-7 -7 -12 -16 -12 -21 0 -5 7 -1 16 8 9 9 14 18 12 20
-2 3 -10 -1 -16 -7z"
        />
        <path
          d="M5265 8170 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M5445 8160 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"
        />
        <path
          d="M5970 8161 c0 -5 6 -11 13 -14 8 -3 14 1 14 9 0 8 -6 14 -14 14 -7 0
-13 -4 -13 -9z"
        />
        <path
          d="M5480 8150 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M5094 8136 c11 -9 23 -22 27 -28 5 -8 8 -5 6 9 0 12 -3 20 -5 18 -3
-2 -14 0 -26 6 -20 10 -20 10 -2 -5z"
        />
        <path d="M5216 8142 c-2 -4 -1 -14 5 -22 7 -12 9 -10 9 8 0 23 -5 28 -14 14z" />
        <path d="M6286 8113 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
        <path
          d="M5067 8114 c-4 -4 -7 -14 -7 -23 0 -11 3 -12 11 -4 6 6 10 16 7 23
-2 6 -7 8 -11 4z"
        />
        <path
          d="M5160 8109 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M5021 8101 c-9 -6 -10 -11 -2 -15 6 -4 11 -2 11 4 0 6 5 8 10 5 6 -3
10 -1 10 4 0 13 -10 14 -29 2z"
        />
        <path
          d="M4833 8085 c0 -8 4 -12 9 -9 4 3 8 9 8 15 0 5 -4 9 -8 9 -5 0 -9 -7
-9 -15z"
        />
        <path
          d="M5000 8066 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"
        />
        <path
          d="M5062 8058 c5 -15 28 -23 28 -10 0 5 -7 13 -16 16 -10 4 -14 1 -12
-6z"
        />
        <path
          d="M5020 8041 c-14 -28 -13 -33 8 -20 10 7 19 18 19 25 0 20 -15 17 -27
-5z"
        />
        <path
          d="M4960 8041 c0 -6 5 -11 11 -11 5 0 8 -4 4 -9 -3 -5 -1 -12 5 -16 16
-10 12 21 -5 35 -10 9 -15 9 -15 1z"
        />
        <path
          d="M4830 8030 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M4868 8008 c-10 -10 -14 -18 -9 -18 10 0 36 29 30 34 -2 1 -12 -6
-21 -16z"
        />
        <path
          d="M4928 8018 c18 -18 14 -28 -10 -25 -14 1 -22 -3 -20 -10 1 -7 -3 -13
-10 -13 -6 0 -9 -2 -6 -5 3 -3 18 0 34 6 29 12 38 15 51 18 8 1 -33 41 -44 41
-4 0 -2 -5 5 -12z"
        />
        <path
          d="M5073 8016 c-26 -12 -30 -26 -8 -26 8 0 15 -4 15 -8 0 -20 -14 -20
-39 -1 -17 14 -27 17 -30 8 -2 -6 4 -16 13 -21 17 -10 22 -38 6 -38 -5 0 -10
6 -10 13 0 16 -37 39 -45 27 -3 -5 4 -14 15 -20 12 -6 19 -18 16 -25 -10 -26
29 -21 55 7 15 16 32 28 39 26 7 -2 15 3 17 10 3 6 -2 12 -11 12 -18 0 -21 15
-6 25 12 7 14 26 3 24 -5 -1 -18 -6 -30 -13z"
        />
        <path
          d="M5134 8016 c-11 -8 -15 -18 -10 -23 5 -4 11 -2 13 5 3 6 11 12 19 12
8 0 14 5 14 10 0 14 -13 12 -36 -4z"
        />
        <path
          d="M4810 7988 c-12 -22 -12 -22 6 -6 10 10 15 20 12 24 -4 3 -12 -5 -18
-18z"
        />
        <path
          d="M4770 7980 c-6 -11 -7 -20 -2 -20 6 0 12 9 15 20 3 11 3 20 1 20 -2
0 -8 -9 -14 -20z"
        />
        <path
          d="M4696 7965 c8 -37 14 -45 14 -19 0 10 -5 26 -10 34 -8 11 -9 7 -4
-15z"
        />
        <path
          d="M4837 7964 c-16 -9 -27 -17 -25 -19 8 -8 47 8 52 21 6 17 8 17 -27
-2z"
        />
        <path d="M4775 7941 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z" />
        <path
          d="M4874 7935 c-4 -9 -19 -15 -35 -15 -18 0 -29 -5 -29 -14 0 -17 33
-31 38 -17 2 6 7 9 12 6 5 -3 11 4 15 15 3 11 11 20 16 20 5 0 9 5 9 10 0 16
-20 12 -26 -5z"
        />
        <path d="M4668 7923 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path
          d="M4740 7921 c0 -5 5 -11 12 -13 7 -3 9 -11 4 -24 -6 -12 -10 -15 -13
-6 -8 21 -35 15 -28 -7 4 -14 -1 -20 -20 -25 -14 -4 -30 -4 -35 -1 -6 4 -10 1
-10 -4 0 -6 4 -11 10 -11 5 0 6 -8 3 -17 -6 -17 -5 -17 12 0 14 15 20 16 26 5
7 -10 11 -7 15 9 3 12 14 25 25 28 21 7 32 35 23 59 -7 17 -24 22 -24 7z"
        />
        <path
          d="M4660 7866 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
-15 -2 -15 -4z"
        />
        <path d="M4600 7821 c0 -9 30 -24 36 -18 2 1 -6 8 -16 15 -11 7 -20 8 -20 3z" />
        <path
          d="M4540 7809 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path d="M4566 7794 c-8 -21 -8 -21 7 -21 7 0 13 9 13 19 0 22 -12 24 -20 2z" />
        <path
          d="M4645 7780 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M4435 7740 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path d="M4467 7717 c-3 -9 1 -18 9 -21 16 -6 18 2 5 22 -7 11 -9 11 -14 -1z" />
        <path
          d="M4414 7708 c-5 -7 -2 -8 6 -3 10 6 12 4 7 -8 -3 -9 -1 -19 4 -23 7
-4 0 -13 -15 -23 -14 -10 -23 -20 -20 -24 11 -10 47 31 50 57 3 26 -20 43 -32
24z"
        />
        <path
          d="M4467 7667 c-9 -23 -1 -37 21 -37 11 0 13 3 6 8 -7 4 -13 16 -16 27
-3 19 -4 19 -11 2z"
        />
        <path d="M4333 7625 c0 -18 19 -15 25 3 2 7 -3 12 -10 12 -8 0 -15 -7 -15 -15z" />
        <path
          d="M4426 7614 c-9 -24 -7 -28 9 -14 17 14 36 5 28 -15 -8 -23 5 -17 18
8 6 12 8 19 4 15 -4 -4 -14 -1 -22 7 -19 19 -29 19 -37 -1z"
        />
        <path
          d="M4391 7592 c-1 -8 8 -16 19 -19 11 -3 17 -9 15 -14 -3 -4 -12 -6 -19
-3 -17 6 -41 -22 -26 -31 5 -3 10 -1 10 4 0 6 9 11 20 11 11 0 20 5 20 11 0 6
7 8 16 5 14 -5 15 -4 4 9 -7 8 -21 15 -30 15 -10 0 -21 6 -23 13 -4 9 -6 9 -6
-1z"
        />
        <path
          d="M4320 7580 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
        />
        <path
          d="M4293 7539 c0 -16 3 -26 6 -22 4 4 9 10 13 13 3 3 1 13 -6 22 -11 15
-13 14 -13 -13z"
        />
        <path
          d="M4340 7549 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M4215 7460 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"
        />
        <path
          d="M4357 7463 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"
        />
        <path
          d="M4121 7442 c-1 -8 -6 -10 -13 -6 -10 5 -10 4 -1 -6 15 -16 28 -8 20
12 -4 11 -6 11 -6 0z"
        />
        <path
          d="M4186 7435 c4 -8 11 -15 16 -15 6 0 5 6 -2 15 -7 8 -14 15 -16 15 -2
0 -1 -7 2 -15z"
        />
        <path
          d="M4129 7390 c0 -3 -2 -13 -4 -22 -2 -10 3 -18 11 -18 8 0 14 3 14 8 0
9 -19 39 -21 32z"
        />
        <path d="M4060 7341 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z" />
        <path d="M4096 7313 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
        <path
          d="M4130 7268 c0 -7 -7 -25 -14 -40 -14 -27 -14 -28 9 -28 13 0 27 5 31
12 4 6 3 8 -3 5 -6 -4 -14 0 -17 9 -9 22 -8 24 9 24 8 0 15 7 15 15 0 8 -7 15
-15 15 -8 0 -15 -6 -15 -12z"
        />
        <path d="M4087 7245 c-4 -8 -2 -17 3 -20 6 -4 10 3 10 14 0 25 -6 27 -13 6z" />
        <path d="M4174 7239 c-3 -6 -1 -16 5 -22 8 -8 11 -5 11 11 0 24 -5 28 -16 11z" />
        <path
          d="M4050 7220 c-10 -7 -11 -10 -2 -10 6 0 12 -12 12 -26 0 -14 4 -23 9
-19 9 5 9 50 0 59 -3 3 -11 1 -19 -4z"
        />
        <path
          d="M4160 7184 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"
        />
        <path
          d="M4020 7180 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
        />
        <path
          d="M4095 7180 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M3995 7150 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"
        />
        <path
          d="M4210 7147 c0 -8 7 -21 16 -28 14 -11 15 -10 12 11 -3 26 -28 41 -28
17z"
        />
        <path
          d="M3947 7133 c-3 -3 1 -12 9 -20 8 -9 18 -13 21 -10 3 3 -1 13 -10 21
-8 8 -17 12 -20 9z"
        />
        <path
          d="M4033 7126 c-10 -11 -9 -15 2 -20 9 -3 15 0 15 8 0 8 6 17 13 19 8 4
7 6 -3 6 -8 0 -20 -6 -27 -13z"
        />
        <path d="M3896 7097 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z" />
        <path
          d="M3994 7084 c-10 -8 -13 -13 -6 -9 6 3 12 1 12 -4 0 -6 7 -11 15 -11
17 0 19 9 7 27 -6 10 -12 10 -28 -3z"
        />
        <path
          d="M4080 7070 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
        />
        <path
          d="M3980 7035 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"
        />
        <path
          d="M3955 7020 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M3860 7010 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M3895 6990 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"
        />
        <path
          d="M3936 6984 c-9 -24 -7 -27 9 -10 17 17 19 26 6 26 -5 0 -12 -7 -15
-16z"
        />
        <path
          d="M3970 6978 c0 -32 14 -58 34 -64 l21 -7 -23 -10 c-23 -11 -25 -28 -4
-35 7 -2 12 3 12 12 0 9 6 16 14 16 27 0 27 20 1 32 -14 6 -24 17 -21 24 3 8
0 11 -8 8 -7 -3 -13 2 -13 10 0 8 4 13 9 10 4 -3 8 0 8 5 0 6 -7 11 -15 11 -8
0 -15 -6 -15 -12z"
        />
        <path
          d="M3900 6950 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M4020 6950 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M3930 6923 c-13 -15 -12 -16 3 -10 9 3 17 2 17 -3 0 -6 7 -10 16 -10
13 0 14 3 5 13 -6 8 -8 17 -4 20 3 4 0 7 -8 7 -8 -1 -21 -8 -29 -17z"
        />
        <path
          d="M3880 6919 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M3890 6838 c0 -4 7 -8 15 -8 8 0 15 4 15 8 0 5 -7 9 -15 9 -8 0 -15
-4 -15 -9z"
        />
        <path
          d="M3924 6815 c-6 -23 -17 -29 -51 -28 -7 1 -13 -3 -13 -8 0 -18 50 -9
62 11 7 11 18 20 25 20 7 0 10 7 7 15 -9 23 -23 18 -30 -10z"
        />
        <path
          d="M3824 6799 c-4 -7 -3 -9 4 -5 5 3 13 0 15 -6 4 -9 6 -10 6 -1 1 17
-16 25 -25 12z"
        />
        <path
          d="M3785 6780 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"
        />
        <path
          d="M3835 6750 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M3960 6740 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M3755 6710 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path d="M3836 6677 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z" />
        <path
          d="M3740 6659 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M3770 6624 c15 -17 30 -18 30 -1 0 7 -9 13 -21 14 -17 2 -18 -1 -9
-13z"
        />
        <path
          d="M3820 6626 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"
        />
        <path
          d="M3810 6590 c0 -5 -7 -10 -15 -10 -8 0 -15 -5 -15 -10 0 -7 6 -7 19 0
24 12 36 30 22 30 -6 0 -11 -4 -11 -10z"
        />
        <path
          d="M3820 6561 c0 -5 9 -7 20 -4 11 3 20 7 20 9 0 2 -9 4 -20 4 -11 0
-20 -4 -20 -9z"
        />
        <path d="M3790 6540 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
        <path
          d="M3735 6470 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path d="M3776 6451 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z" />
        <path
          d="M3830 6449 c0 -5 -4 -8 -9 -5 -5 3 -11 0 -14 -8 -4 -11 1 -12 21 -7
29 8 42 31 18 31 -9 0 -16 -5 -16 -11z"
        />
        <path
          d="M3823 6318 l-21 -10 24 -23 c17 -16 21 -24 12 -27 -7 -3 -3 -7 10
-10 19 -5 21 -3 16 17 -3 14 1 28 11 39 9 9 12 16 7 16 -6 0 -16 2 -24 4 -7 3
-23 0 -35 -6z m37 -18 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10
8 0 15 -4 15 -10z"
        />
        <path d="M3800 6264 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z" />
        <path d="M3777 6229 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z" />
        <path
          d="M3820 6233 c8 -3 19 -11 23 -17 6 -7 7 -6 4 4 -2 8 -12 16 -23 17
-13 2 -15 1 -4 -4z"
        />
        <path
          d="M3859 6138 c0 -2 -2 -11 -4 -21 -3 -15 -1 -16 11 -6 7 6 11 15 8 20
-5 9 -13 12 -15 7z"
        />
        <path d="M1440 6116 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z" />
        <path d="M9096 6104 c-17 -12 -17 -14 -4 -14 9 0 20 5 23 11 11 17 2 18 -19 3z" />
        <path
          d="M1603 6103 c-7 -2 -13 -9 -13 -14 0 -4 7 -6 15 -3 8 4 23 0 32 -7 15
-13 15 -12 2 9 -14 23 -16 24 -36 15z"
        />
        <path d="M3870 6101 c0 -5 9 -11 19 -14 14 -3 18 -1 14 9 -6 15 -33 19 -33 5z" />
        <path d="M1607 6073 c-10 -9 -9 -23 1 -23 5 0 9 7 9 15 0 17 -1 18 -10 8z" />
        <path d="M1567 6049 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z" />
        <path d="M1630 6049 c0 -15 18 -19 23 -6 3 8 -1 14 -9 14 -8 0 -14 -4 -14 -8z" />
        <path d="M1677 6029 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z" />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLogo;
